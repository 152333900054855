.not-found-page {
  padding-top: 187px;

  .sub-heading {
    margin-top: 20px;
    margin-bottom: 20px;

    font-style: italic;
  }

  a {
    font-weight: 700;
    color: $red;
  }
}