header {
  a {
    color: $blue;
  }

  svg {
    margin-right: 30px;
  }
}

.divider {
  margin-bottom: 30px;
}

@include respond-to(xs) {
  header {
    .heading {
      font-size: 20px;
    }

    svg {
      margin-right: 16px;
    }
  }
}
