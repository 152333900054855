.notice-holder {
  .description {
    width: calc(100% - 180px);
    font-size: 15px;
  }

  .pricing-wrapper {
    width: 180px;
    margin: 16px 0;;

    span {
      font-size: 14px;
    }

    .price {
      font-size: 26px;
      font-weight: 700;
      color: $blue;
    }
  }
}

@include respond-to(xs) {
  .notice-holder {
    flex-wrap: wrap;

    .description {
      width: 100%;
      margin-top: 15px;
    }

    .pricing-wrapper {
      margin-bottom: 0;
      text-align: left;
    }
  }
} 