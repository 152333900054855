.box-holder {
    background-color: $white;
    border-radius: 8px;
    max-width: 50%;
    margin: 0 15px;
    padding: 20px;

    .title {
        margin-bottom: 14px;
        color: $blue;
        text-align: center;
    }

    .desc {
        min-height: 100px;
        font-size: 15px;
        color: #393939;
        text-align: center;
    }

    .pricing-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .pricing-holder {
            width: 50%;
            margin: 16px 0;
            padding: 0 15px;
            text-align: right;

            span {
                font-size: 14px;
            }

            .price {
                font-size: 25px;
                font-weight: 700;
                color: $blue;
            }
        }

        .sub-pricing-holder {
            position: relative;
            width: 50%;
            margin: 16px 0;
            padding: 0 15px;

            ul {
                list-style: none;
                padding-left: 0;
                text-align: left;
                font-size: 10px;
            }

            &:after {
                content: '';
                position: absolute;
                top: -7px;
                bottom: -6px;
                left: 0;
                width: 1px;
                background: #D1D1D1;
            }
        }
    }

    // .number-input {
    //     display: flex;
    //     justify-content: center;
    //     height: 40px;
    //     box-shadow: 3px 0 6px 0px #ccc;
    //     max-width: 200px;
    //     margin: 0 auto;
    //     border-radius: 8px;
    // }

    // .quantity {
    //     border: 0;
    //     text-align: center;
    //     width: 100%;
    //     max-width: calc(100% - 80px);
    // }

    // .qty-button {
    //     border: 0;
    //     width: 40px;
    //     background-color: $blue;
    //     color: white;
    //     padding: 0;
    //     font-size: 26px;

    //     &.plus {
    //         border-top-right-radius: 8px;
    //         border-bottom-right-radius: 8px;
    //     }

    //     &.minus {
    //         border-top-left-radius: 8px;
    //         border-bottom-left-radius: 8px;
    //     }
    // }
}


@include respond-to(md-down) {
  .box-holder {
    .pricing-wrapper {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .sub-pricing-holder:after {
        display: none;
      }
      
      .pricing-holder, .sub-pricing-holder {
        margin: 0;
        margin-bottom: 16px;
        width: 100%;
        text-align: center;

        ul {
          text-align: center;
        }
      }
    }
  }
} 

@include respond-to(sm-down) {
  .box-holder {
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    .pricing-wrapper {
      flex-direction: row;
      align-items: flex-start;

      .sub-pricing-holder:after {
        display: block;
      }
      
      .pricing-holder, .sub-pricing-holder {
        margin: 16px 0;
        width: 100%;
        text-align: right;

        ul {
            text-align: left;
        }
      }
    }
    
    .desc {
      min-height: auto;
    }
  }
} 

@include respond-to(xs) {
    .box-holder {
        .pricing-wrapper {
            flex-direction: column;
            align-items: center;
            

            .sub-pricing-holder:after {
                display: none;
            }
            
            .pricing-holder, .sub-pricing-holder {
                margin: 0;
                margin-bottom: 16px;
                width: 100%;
                text-align: center;

                ul {
                    text-align: center;
                }
            }
        }
    }
} 