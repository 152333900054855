.notice-holder {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #1c2e4a;
  border-radius: 8px;
  min-height: 100px;
  padding: 20px;
  margin: 30px 0;

  .title {
    display: flex;
    align-items: center;
    position: absolute;
    top: -15px;
    left: 15px;
    background: #f2f5f9;
    margin-bottom: 14px;
    color: $blue;
    padding: 0 15px;
    font-size: 18px;
  }

  .tools-list,
  .description {
    width: 70%;
    list-style: none;
    padding-left: 0;
    text-align: left;
    display: flex;
    flex-wrap: wrap;

    .tool-item {
      width: 33.33%;
      display: flex;
      align-items: center;
      margin: 5px 0;
    }

    svg {
      width: 25px;
      display: flex;
      margin-right: 13px;
    }

    p {
      width: 100%;
    }
  }

  .status {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 30%;
    background-color: #d2e9d6;
    border: 1px solid #229033;
    color: #229033;
    border-radius: 100px;
    padding: 9px;
    margin-right: 0;
    margin-left: auto;
    max-width: 136px;
    display: block;

    svg {
      font-size: 20px;
    }

    span {
      padding: 0 5px;
    }
  }
}

@include respond-to(lg-up) {
  .notice-holder {
    .tools-list {
      position: relative;
  
      &:before {
        content: '';
  
        position: absolute;
        left: 29%;
        top: 0;
  
        height: 100%;
  
        border-left: 1px solid $light-grey;
      }
  
      &:after {
        content: '';
  
        position: absolute;
        left: 62%;
        top: 0;
  
        height: 100%;
  
        border-left: 1px solid $light-grey;
      }
    }
  }
}

@include respond-to(md-down) {
  .notice-holder {
    .tools-list {
      padding-top: 20px;
      width: 100%;
    }
  }
}

@include respond-to(sm-down) {
  .notice-holder {
    .tools-list .tool-item {
      width: 50%;
    }

    .status {
      width: 31%;
    }
  }
}

@include respond-to(xs) {
  .notice-holder {
    flex-wrap: wrap;

    .tools-list {
      .tool-item {
        width: 100%;
      }
    }

    .status {
      margin-left: 0;
      margin-top: 15px;
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
    }
  }
}
