.card-box {
  padding: 30px 20px;
  margin-top: 30px;

  background-color: $white;
}

.t-price {
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  .t-price-description {
    margin-top: 5px;
    margin-right: 15px;
    font-size: 14px;
    display: flex;
    font-weight: 100;
  }
}

.t-note,
span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
