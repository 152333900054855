.session-refill-page {
  .action-holder {
    margin-top: 30px;
  }
}

.session-refill {
  p a {
    display: inline;
  }

  p, a {
    color: $night-rider-blue;
  }

  input {
    padding: 10px 20px;
    margin: 17px 10px 0;
    // width: 120px;
    width: 103px;
    height: 40px;

    font-size: 15px;
    color: $night-rider-blue;

    border: 0;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 6px;

    &::placeholder {
      color: $gainsboro-grey;
    }
  }

  .btn-blue {
    color: $white;
  }
}

.note-holder {
  .t-refill-note {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}