@keyframes progress-animation {
  0% {width: 0%;}
  20% {width: 10%;}
  40% {width: 30%;}
  50% {width: 60%;}
  100% {width: 100%;}
}

.loading-wrapper {
  padding: 290px 119px;

  .sub-heading {
    margin-top: 30px;
    margin-bottom: 47px;
  }
}

.loading-bar {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 10px;
  overflow: hidden;

  background-color: $white;
  box-shadow: inset 0px 0px 0px 1px $white;
  border-radius: 50px;

  .progress-bar {
    display: flex;
    width: 100%;
    height: 100%;

    background: $blue;

    border-radius: 50px;
    animation: progress-animation 5s ease-in-out;
  }
}