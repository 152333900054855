.add-session-info {
  .circle-wrapper {
    margin: 0 auto;
  }

  .t-card-desc {
    margin-top: 20px;
  }
}

.add-session-action {
  .session-list {
    position: relative;

    & > div {
      width: 50%;
    }

    & > div:first-child {
      padding-right: 20px;

      border-right: 1px solid $light-grey;
    }

    & > div:last-child {
      padding-left: 20px;
    }

    .warning {
      position: absolute;
      bottom: -18px;
      right: 0;

      color: $red;
    }
  }

  .def-number-input {
    margin-right: 0;
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  li {
    font-size: 10px;
    line-height: 12px;
  }

  .t-card-title {
    margin-bottom: 0;
  }
}

@include respond-to(md) {
  .add-session-action {
    .session-list {
      & > div:first-child {
        padding-right: 8px;
      }
    }

    .def-number-input {
      max-width: 141px;
    }

    .t-price {
      font-size: 20px;
    }
  }
}

@include respond-to(sm-down) {
  .add-session-action {
    .session-list {
      & > div {
        width: 100%;

        text-align: center;

        & * {
          width: 100%;
        }
      }
  
      & > div:first-child {
        padding-right: 0;
  
        border-right: 0;
      }

      & > div:last-child {
        padding-left: 0;
      }
    }

    .def-number-input {
      order: 1;
      margin-top: 20px;
      margin-right: auto;
    }

    ul {
      order: 2;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .t-card-title {
      text-align: center;
    }
  }
}