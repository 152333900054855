.card-box-border {
  position: relative;

  padding: 20px;
  margin: 30px 0;
  min-height: 100px;

  background-color: transparent;
  border: 1px solid #1c2e4a;
  border-radius: 8px;

  .box-border-info {
    width: 611px;
    max-width: 100%;
  }

  .box-border-info-full {
    max-width: 100%;
  }

  .t-border-title {
    position: absolute;
    top: -11px;
    left: 15px;

    padding: 0 15px;

    font-size: 18px;
    font-weight: 700;

    background: #f2f5f9;
  }
}

@include respond-to(md-down) {
  .card-box-border {
    .t-border-description {
      margin-bottom: 15px;
    }
  }
}
