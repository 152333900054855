.session {
  h2 {
    margin-bottom: 30px;
  }

  .link-nonunderline {
    text-decoration: none;
  }
}

.subscription-info {
  padding-top: 10px;
  padding-bottom: 10px;

  .flex-wrapper {
    & > div {
      padding-bottom: 20px;
    }
  }

  .provider-item {
    margin-bottom: 15px;
  }

  .action-holder a {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .t-icon-link {
    a {
      display: flex;
      justify-items: flex-start;

      @include on-event {
        .t-icon-label {
          color: $hover-blue;
        }

        .t-icon svg {
          fill: $hover-blue;
        }
      }
    }

    span {
      transition: all .3s ease-in-out;
    }

    &:first-of-type {
      padding-top: 11px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .t-icon-label {
    font-size: 16px;
    font-weight: 500;
  }

  .t-icon {
    width: 19px;
  }

  h4 {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  a {
    color: $blue;
    text-decoration: none;
  }

  a.btn-blue {
    color: $white;
  }
}

.recording-archive {
  .content-holder {
    width: 611px;
    max-width: 100%;
  }
}

.total-holder {
  .provider-item {
    max-width: 100%;
    text-align: left;
    display: flex;
    align-items:center;
    
    &:not(:last-child) {
      margin-right: 15px;
    }
  
  }
  
  .t-price {
    margin-top: 30px;
    margin-bottom: 19px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    .t-price-description{
      margin: 0px;
    }
    .t-operator{
      font-size: 15px;
    }
  }

  .t-note {
    margin-bottom: 30px;
  }

  .t-card-title {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .new-plan {
    .t-price {
      margin-top: 20px;
    }
  }
}

.record-info {
  .record-billing:not(:first-child) {
    margin-left: 20px;
  }
  
  .t-val {
    font-size: 25px;
    font-weight: 700;
  }
  
  .t-desc,
  .t-date {
    font-size: 14px;
  }
}

@include respond-to(lg-up) {
  .subscription-info {
    .flex-wrapper {
      & > div {
        flex-grow: 1;
      }
    }

    .session-holder {
      padding-left: 21px;
      padding-right: 21px;
      margin-left: 20px;
      margin-right: 20px;

      border-left: 1px solid $light-grey;
      border-right: 1px solid $light-grey;
    }
  }
}

@include respond-to(md-down) {
  .subscription-info {
    h4 {
      text-align: center;
    }

    .flex-wrapper {
      & > div {
        width: 100%;

        text-align: center;

        &:not(:first-child) {
          h4 { padding-top: 0; }
        }
      }

      & > div:nth-child(2) {
        padding-top: 21px;
        padding-bottom: 21px;
        // margin-top: 20px;
        margin-bottom: 20px;
  
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
      }
    }

    .circle-wrapper,
    .btn-blue {
      margin: 0 auto;
    }

    .action-holder {
      margin-top: 20px;
      width: 100%;
      
      text-align: center;
      
      a {
        margin-left: auto;
        margin-right: auto;
        width: 219px;
      }
    }
  }

  .group-session {
    .pricing-wrapper {
      width: 100%;
    }
  }
  
  .recording-archive {
    .record-holder {
      margin-top: 20px;
      width: 100%;
    }
  }

  .record-info {
    justify-content: center;
  }
}