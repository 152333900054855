.alert {
  padding: 20px;
  margin-bottom: 30px;

  font-family: $font-aktiv-grotesk;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  border-radius: 6px;

  .t-action {
    font-weight: 400;
  }

  a {
    text-decoration: underline;
  }
}

.alert-error {
  background-color: $light-red;
  color: $red;

  a {
    color: $red;
  }
}

.alert-success {
  background-color: $light-blue;
  color: $green;

  a {
    color: $green;
  }
}