.cancel-subscription-page {
  .contact-info {
    margin-bottom: 200px;

    .t-email a {
      text-decoration: underline;
    }

    p {
      svg {
        margin-right: 10px;
        vertical-align: sub;
    
        font-size: 20px;
        fill: $blue;
      }

      &:first-child{
        margin-bottom: 21px;
      }
    }

    p, a {
      font-size: 15px;
      color: $night-rider-blue;
    }

    a, span {
      font-weight: 700;
    }
  }

  .subscription-info-wrapper {
    .card-box {
      padding-top: 25px;
    }
  }
}

.subscription-info-wrapper {
  .action-holder {
    position: absolute;
    bottom: 30px;
    left: 50%;
  
    transform: translateX(-50%);
  
    .btn-plain {
      color: $red;
  
      border: 0;
  
      cursor: pointer;
    }
  }
}

.cancelling {
  .provider-list:not(:last-child) {
    margin-bottom: 15px;
  }

  .circle-wrapper {
    margin-left: 30px;
  }

  .flex-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.note-holder {
  .t-note {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@include respond-to(xs) {
  .cancelling {
    .circle-wrapper {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}