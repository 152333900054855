.def-number-input {
  &.number-input {
    display: flex;
    justify-content: center;
    height: 40px;
    box-shadow: 3px 0 6px 0px #ccc;
    max-width: 200px;
    margin: 0 auto;
    border-radius: 8px;
  }
  
  .quantity {
    border: 0;
    text-align: center;
    width: 100%;
    max-width: calc(100% - 80px);
  }
  
  .qty-button {
    border: 0;
    width: 40px;
    background-color: $blue;
    color: white;
    padding: 0;
    font-size: 26px;

    transition: all .3s ease-in-out;
  
    &.plus {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
  
    &.minus {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    // @include on-event {
    //   background-color: $manatee-blue;
    // }
  }

  .btn-disabled {
    background-color: $manatee-blue;
    
    pointer-events: none;
  }
}