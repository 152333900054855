.circle-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;

  background: transparent
    radial-gradient(
      closest-side at 50% 50%,
      #ffffff 0%,
      #ffffff33 83%,
      #1c2e4a33 100%,
      #0377b933 100%
    )
    0% 0% no-repeat padding-box;
  border: 2px dashed $blue;
  border-radius: 100%;

  h3 {
    font-weight: 700;
    font-size: 30px;
  }

  span {
    font-weight: 400;
    font-size: 13px;
  }
  .circle-pending-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    opacity: 0.7;
    h3 {
      font-size: 20px;
    }
    span {
      margin-top: -4px;
      font-size: 10px;
    }
  }
}
