.success-message {
  .sub-heading {
    margin: 0 auto;
    width: 524px;
    max-width: 100%;
  }

  .circle-wrapper {
    margin: 0 auto;
  }

  .btn-transparent {
    margin-top: 30px;
  }

  .alert-error {
    margin-top: 30px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
}

.payment-message {
  p {
    padding-bottom: 30px;
  
    font-family: $font-aktiv-grotesk;
    font-weight: 400;
    font-size: 14px;
  }
}

.session-refill {
  .content-holder {
    width: 651px;
    max-width: 100%;
  }

  .action-holder {
    align-self: center;
  }
}

@include respond-to(xs) {
  .session-refill {
    .content-holder,
    .action-holder {
      width: 100%;
    }

    .btn-blue {
      margin: 0 auto;
      margin-top: 30px;
    }

    .t-card-title,
    .t-card-desc {
      text-align: center;
    }
  }
}