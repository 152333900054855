.toggle-wrapper {
  p {
    padding-left: 15.25px;

    font-size: 15px;
    color: $night-rider-blue;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 33.75px;
  height: 22.5px;

  input { 
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $blue;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $blue;
    }

    &:checked + .slider:before {
      transform: translateX(9px);
    }
  }
  
  .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $ghost-grey;
    border-radius: 34px;

    cursor: pointer;
    transition: all .4s ease-in-out;

    &:before {
      content: "";

      position: absolute;
      bottom: 3px;
      left: 4px;

      width: 16.87px;
      height: 16.87px;

      background-color: $white;
      border-radius: 50%;

      transition: .4s;
    }
  }
}