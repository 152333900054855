*,
*:before,
*:after {
  margin: 0;
  box-sizing: border-box;
}
a,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.btn,
a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  @include on-event {
    outline: 0;
    box-shadow: none;
  }
}
/**
 * Basic styles for input fields
 */
input[type="email"],
input[type="password"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-focus-ring-color: none;
  outline: none;
  @include on-event {
    box-shadow: none;
  }
}
input,
select,
button,
textarea {
  outline: none;
  @include appearance(none);
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @include appearance(none);
  }
}

.custom-container {
  margin: 0 auto;
  width: 1000px;
  max-width: 100%;
}

html {
  background-color: $alice-blue;
}

.app-wrapper {
  position: relative;

  padding: 30px;
  overflow-x: hidden;
}

.welcome-body {
  display: flex;
  justify-content: center;
  font-size: 28px;
}
