.plan-selection-container {
  .boxes-wrapper {
    background: #f2f5f9;
    display: flex;
    justify-content: space-around;
    margin: 0 -15px;
  }

  .box-holder .warning {
    display: flex;
    justify-content: center;
    padding-top: 10px;

    color: $red;
  }
}

@include respond-to(sm-down) {
  .plan-selection-container {
    .group-session {
      .t-price {
        text-align: center;
      }
    }

    .box-holder:first-child {
      .pricing-holder {
        text-align: center;
      }
    }

    .boxes-wrapper {
      flex-wrap: wrap;
      margin-top: -15px;
      margin-bottom: -15px;
    }

    .provider-list-holder  {
      flex-wrap: wrap;
    }

    .provider-item:first-child {
      .t-price {
        margin-bottom: 0;
      }
    }
  }
} 