body {
  font-family: $font-aktiv-grotesk, sans-serif;;  
  font-weight: 400;
  color: $blue;

  .text-center {
    text-align: center;
  }
}

.t-nunito {
  font-family: 'Nunito Sans', sans-serif;;
  font-weight: 700;
}

.text-left {
  text-align: left;
}

.heading {
  @extend .t-nunito;

  font-size: 25px;
}

.sub-heading {
  font-weight: 400;
  font-size: 18px;
}

.t-card-title {
  @extend .t-nunito;

  margin-bottom: 5px;
  
  font-size: 20px;
  line-height: 27px;
  text-align: left;
}

.t-card-desc {
  font-size: 15px;
  text-align: left;
}