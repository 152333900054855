.single-box-holder {
    background-color: $white;
    border-radius: 8px;
    padding: 20px;
    text-align: left;

    .title {
        margin-bottom: 14px;
        color: $blue;
    }

    .t-card-desc {
        margin-bottom: 15px;
        font-size: 15px;
        color: #393939;
    }

    .form-wrapper {
        display: flex;
        align-items: baseline;

        span {
            font-size: 14px;

            &.separator {
                font-weight: 900;
                padding: 0 40px;
            }

            &.separator-plus {
                font-weight: 900;
                padding: 0 15px;
            }
        }

        .price {
            font-size: 26px;
            font-weight: 700;
            color: $blue;
            line-height: 24px;
        }

        .pricing-option {
            display: flex;
            align-items: flex-end;
            position: relative;
            padding: 2px 2px 2px 50px;
            cursor: pointer;
            font-size: 25px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:hover input ~ .checkmark {
                background-color: $blue;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;

                &:checked ~ .checkmark {
                    background-color: $blue;
                }

                &:checked ~ .checkmark:after {
                    display: block;
                }
            }    
            
            .checkmark {
                position: absolute;
                top: -4px;
                left: 0;
                height: 35px;
                width: 35px;
                background-color: $white;
                border: 1px solid $blue;
                border-radius: 50%;

                transition: all .3s ease-in-out;

                &:after {
                    content: "";

                    position: absolute;
                    top: 5px;
                    left: 12px;

                    display: none;
                    width: 9px;
                    height: 17px;

                    border: solid white;
                    border-width: 0 3px 3px 0;

                    transform: rotate(45deg);
                }
            }

            .additional-price {
                display: flex;
                align-items: flex-end;
            }

            &:last-child .separator {
                display: none;
            }
        }
    }
}

@include respond-to(md-down) {
    .single-box-holder {
        .form-wrapper {
            span.separator {
                padding: 0 14px;
            }
        }
    }
} 

@include respond-to(sm-down) {
  .single-box-holder {
    .form-wrapper {
      display: flex;
      flex-wrap: wrap;

      .pricing-option {
        width: 100%;
        display: block;
        margin: 10px 0;

        .additional-price {
          align-items: center;
          flex-wrap: wrap;
        }

        .checkmark {
          top: 0;
        }
      }

      span.separator {
        padding: 15px 0;
      }

      span.separator-plus {
        width: 100%;
        display: block;
        padding: 15px 0;
      }

      .price {
        font-size: 19px;
      }
    }
  }
} 