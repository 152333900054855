.btn {
  display: flex;
  justify-content: center;
  padding-top: 9px;
  padding-bottom: 9px;
  // align-items: center;

  // height: 40px;
  
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: $white;

  border: 0;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 6px;

  transition: all .3s ease-in-out;
}

.btn-blue {
  @extend .btn;

  width: 219px;

  background-color: $blue;
}

.btn-transparent {
  @extend .btn;

  color: $blue;

  background-color: transparent;
  border: 1px solid $blue;
}

.btn-plain {
  @extend .btn;

  color: $blue;
  text-decoration: underline;

  background-color: transparent;
  box-shadow: none;
}

.btn-manatee-blue {
  @extend .btn;

  width: 219px;

  background-color: $manatee-blue;
}

.btn-full {
  width: 100%;
}

.btn-disabled {
  background-color: $manatee-blue;
  
  pointer-events: none;
}

@include respond-to(md-up) {
  .btn-blue {
    @include on-event {
      background-color: $hover-blue;
    }
  }

  .btn-transparent {
    @include on-event {
      color: $white;
      
      background-color: $blue;
    }
  }

  .btn-plain {
    @include on-event {
      color: $hover-blue;
    }
  }

  .btn-manatee-blue {
    @include on-event {
      background-color: $blue;
    }
  }
}