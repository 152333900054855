@mixin vertical-divider(
    $primaryColor: #eee, 
    $secondaryColor: #aaa,
    $dividerWidth: 2px) 
{
	width: 1px;
	height: 80px;
    
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 4px;
        border-left: $dividerWidth solid $primaryColor;
        background-color: $secondaryColor;
    }
}

.horizontal-split{
  margin: 15px 0;
}

.split {
  position: relative;
  margin: 0 25px 0 0;
  @include vertical-divider($primaryColor: #eee, $secondaryColor: #aaa);
}

.current-subscription {
  margin-top: 0px;

  .provider-list-holder{
    display: flex;
    align-items:center;
  }

  .provider-item:not(:last-child) {
    margin-right: 15px;
  }
  .t-card-title {
    margin-bottom: 0;
  }

  .t-card-desc {
    color: $night-rider-blue;
  }

  .t-price {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    .t-price-description{
      margin: 0px;
    }
    .t-operator{
      font-size: 15px;
    }
  }

  span {
    font-weight: 700;
  }
}

.update-provider {
  .provider-info {
    padding-right: 30px;

    width: 670px;
    max-width: 100%;
  }

  .t-price {
    margin-bottom: 18px;
  }
}

.new-subscription-holder {
  margin-top: 30px;

  .provider-list {
    margin-bottom: 30px;
    width: 165px;
    max-width: 100%;

    text-align: left;

    &:last-child {
      margin-left: 20px;
    }

    .t-note {
      margin-bottom: 0;
    }
  }

  .t-card-title {
    margin-bottom: 20px;
  }

  .t-price {
    margin-top: 0;
    margin-bottom: 0;
  }

  .t-note {
    margin-bottom: 30px;
  }
}

@include respond-to(md) {
  .update-provider {
    .provider-info {
      width: 66%;
    }
  }
}

@include respond-to(sm-down) {
  .update-provider {
    .provider-info {
      padding-right: 0;
      width: 100%;

      & + div {
        margin-top: 20px;
        width: 100%;

        text-align: center;
      }
    }

    def-number-input {
      margin-left: auto;
    }

    .t-card-title,
    .t-card-desc {
      text-align: center;
    }
  }
}
