//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// Aktiv Grotesk font family
$font-aktiv-grotesk: 'Aktiv Grotesk';

/// Regular font family
/// @type List
$text-font-stack: 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Copy text color
/// @type Color
$text-color: #222 !default;

/// Main brand color
/// @type Color
$brand-color: #E50050 !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../assets/' !default;

// Other Variables

$white: #ffffff;
$black: #000000;
$blue: #1c2e4a;
$hover-blue: #1b417d;
$alice-blue: #F2F5F9;
$light-red: #F7CFD1;
$red: #DA1520;
$light-blue: #D2E9D6;
$green: #229033;
$light-grey: #D1D1D1;
$light-green: #D2E9D6;
$manatee-blue: #8D96A4;
$ghost-grey: #C1BFBF;
$night-rider-blue: #333333;
$gainsboro-grey: #E1E0E0;
